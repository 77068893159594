import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Hamberger from './hambergerMenu'

const HeaderStyle = styled.header`
    z-index: 100;
    width: 100%;
    div.menubar{
        font-family: 'Arvo', serif;
        top: 0;
        z-index: 1000;
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;
        h1.header-company-name {
            display: flex;
            align-items: center;
            a {
                letter-spacing: 0.01rem;
                color: var(--white-color-1);
                font-size: 1.8rem;
                font-weight: 400;
                padding: 10px;
                // position: relative;
                img {
                    // position: absolute;
                    height: 90px;
                }
            }
            span {
                font-size: 0.8rem;
                letter-spacing: 0.1em;
                color: var(--white-color-3);
                font-family: var(--gothic);
                transform: translateY(3px);
                margin-left: 16px;
            }
        }
        nav.header-nav {
            margin-left: 50px;
            font-family: var(--gothic);
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 300px;
            height : 100%;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height : 100%;
                cursor: pointer;
                padding: 0 1.6vw;
                margin: 0 0.4vw;
                color: var(--gray-color-4);
                font-size: 0.9rem;
                font-weight: 500;
                transition: .4s;
                border-top: solid 2px transparent;
                border-bottom: solid 2px transparent;
                box-sizing: border-box;
                &:hover {
                    color: var(--white-color-1);
                    border-bottom: solid 2px var(--white-color-2);
                }
            }
        }
        @media screen and (max-width:1040px) {
            display: none;
        }
    }
`;

function Header(props) {
    return (
        <HeaderStyle style={{background: props.isPageTop ? 'var(--black-color-1)' : 'transparent', position: props.isPageTop ? 'static' : 'absolute'}} >
            <div className="menubar">
                <h1 className='header-company-name'>
                    <Link to='/'>Supreme Technology</Link>
                    <span className='sub'>シュプリーム・テクノロジー</span>
                </h1>
                <nav className='header-nav'>
                    <Link to='/product'>製品紹介</Link>
                    <Link to='/news/1'>お知らせ</Link>
                    <Link to='/company'>会社概要</Link>
                    <Link to='/contact'>お問い合わせ</Link>
                </nav>
            </div>
            <Hamberger />
        </HeaderStyle>
    )
}
export default Header
