import React from 'react'
import styled from 'styled-components'

const ContainerStyle = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 0 40px;
    box-sizing: border-box;
    @media screen and (max-width: 500px) {
        padding: 0 15px;
    }
`

function Container({children, styles}) {
    return (
        <ContainerStyle style={{...styles}}>
            {children}
        </ContainerStyle>
    )
}

export default Container
