import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { slide as Menu } from "react-burger-menu"
import Logo from '../../assets/images/svg/logo.svg'

const HeaderStyle = styled.div`
    margin: 0;
    width: 100%;
    @media screen and (min-width:1040px) {
        display: none;
    }
    #App {
        font-family: var(--gothic);
        height: 100%;
    }
        
    #page-wrap {
        text-align: center;
        
        /* Prevent sidebar from showing a scrollbar on page */
        overflow: auto;
    }
        
    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        z-index: 1000;
        width: 1.5rem;
        height: 1.5rem;
        left: 30px;
        top: 20px;
    }
        
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #fff;
        height: 2px !important;
    }
        
    /* Position and sizing of clickable cross button */
    .bm-menu-wrap {
        .bm-cross-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px !important;
            width: 1.5rem !important;
            height: 1.5rem;
            /* Color/shape of close button cross */
            .bm-cross {
                background: var(--gray-color-2);
                width: 2px !important;
                height: 20px !important;
            }
        }
    }
        
        
    /* General sidebar styles */
    .bm-menu {
        background: var(--white-color-1);
        padding-top: 3rem;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 1.2em;
        .bm-item-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            .bm-item {
                max-width: 300px;
                display: inline-block;
                font-family: var(--gothic);
                font-weight: 400;
                font-size: 16px;
                text-decoration: none;
                margin-bottom: 20px;
                color: #222;
                transition: color 0.4s;
                img {
                    margin-bottom: 10px;
                }
                outline: none;
                padding: 10px 0;
                width: 100%;
                &.logo {
                    width: 100%;
                    svg {
                        min-width: 100px;
                    }
                }
            }
            .bm-item:hover {
                color: #ccc;
            }
        }
    }
        
    /* Styling of overlay */
    .bm-overlay {
        background: rgba(255, 255, 255, 0.6);//効かない
    }          

    .company-name {
        position: fixed;
        left: 74px;
        top: 20px;
        z-index: 1000;
        a {
            color: white;
            font-size: 20px;
            font-family: 'Arvo', serif;
            padding: 15px 5px;
        }
    }
    .hamberger-nav-background {
        position: fixed;
        height: 60px;
        width: 100%;
        background: rgba(10,10,10,0.9);
        z-index: 100;
        display: block;
    }
`;

function Header() {
    return (
        <HeaderStyle>
            <Menu width='100%'>
                <Link to='/' style={{outline: 'none !important'}} className='logo'><Logo /></Link>
                <Link to='/product'  className="menu-item">製品紹介</Link>
                <Link to='/news/1'  className="menu-item">お知らせ</Link>
                <Link to='/company'  className="menu-item">会社概要</Link>
                <Link to='/contact'  className="menu-item">お問い合わせ</Link>
            </Menu>
            <div className='company-name'>
                <Link to='/'>Supreme Technology</Link>
            </div>
            <div className='hamberger-nav-background'></div>
        </HeaderStyle>
    )
}
export default Header
