import React from 'react'
import styled from 'styled-components'

const LightStyle = {
    color: '#5a5a5a',
    border: 'solid 1px #aaa',
}
const DarkStyle = {
    color: '#fff',
    border: 'solid 1px #fff',
}
const ButtonStyle = styled.div`
    font-family: var(--gothic);
    font-weight: 300;
    font-size: 0.8rem;
    display: inline-block;
    padding: 1em 1.2em;
    text-decoration: none;
    border-radius: 1px;
    transition: var(--transition-timing);
    cursor: pointer;
    &:hover {
        background: #fff;
        // font-weight: 500;
        color: #000 !important;
    }
`

function Button({children, isDarkMode}) {
    return (
        <ButtonStyle style={isDarkMode ? DarkStyle : LightStyle}>
            {children}
        </ButtonStyle>
    )    
}
export default Button