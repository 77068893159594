import React from 'react'
import styled from 'styled-components'
import Container from './Container'

const PageComponentStyle = styled.section`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 25px 0;
    overflow: hidden;
    @media screen and (max-width: 500px) {
        padding: 5px 0 15px;
    }
`

function PageComponent({ children, styles, containerStyles }) {
    return (
        <PageComponentStyle style={{...styles}}>
            <Container styles={{...containerStyles}}>
                {children}    
            </Container>            
        </PageComponentStyle>
    )
}

export default PageComponent
