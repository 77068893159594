import React from 'react'
import '../../assets/styles/index.scss'
import Header from './header'
import Footer from './footer'
// import Head from './head'
import SEO from './seo'
import { StateProvider } from '../../contexts/StateProvider';

function Layout(props) {
    return (
        <>
            <SEO 
                title={props?.pageTitle}
                description={props?.description}
                image={props?.image}
                url={props?.article}
            />
            <StateProvider>
                <Header isPageTop={props?.isPageTop} />
                {props.children}
                <Footer />
            </StateProvider>
        </>
    )
}

export default Layout
