import React, { createContext, useState, useEffect } from "react";
import { graphql, useStaticQuery } from 'gatsby'

export const ProductsContext = createContext()

// Wrap the app and provide the data layer to every component
export const StateProvider = ({ children }) => {
    const [passData, setPassData] = useState()
    const data = useStaticQuery(graphql`
        query {
            allContentfulProductCategory (sort: {fields: categoryorder}) {
                edges {
                    node {
                        model
                        price
                        picture {
                            fluid(maxWidth: 1200, quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
            allContentfulProductCategoryProduct (sort: {fields: maker}) {
                edges {
                    node {
                        productname
                        maker
                        model
                        category
                        photo {
                            fluid(maxWidth: 200, quality: 60) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)
    const categoriesByModel = orderContentfulCategories(data.allContentfulProductCategory.edges)
    const productsByCategory = orderContentfulProductsByCategory(data.allContentfulProductCategoryProduct.edges)
    useEffect(() => {
        !(passData) && setPassData([categoriesByModel, productsByCategory]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passData])
    return (
        <ProductsContext.Provider value={passData}>
            {children}
        </ProductsContext.Provider>
    )
}

function orderContentfulProductsByCategory(products) {
    let productsByCategory = {}
    products.forEach(product => {
        const cat = product.node.category 
        if (productsByCategory[cat] === undefined) {
            productsByCategory[cat] = [product.node]
        } else {
            productsByCategory[cat].push(product.node)
        }
    })
    return productsByCategory
}

function orderContentfulCategories(categories) {
    let categoriesByModel = []
    categories.forEach(cat => {
         const model = cat.node.model
         const pic = cat.node.picture
         const price = cat.node.price
         categoriesByModel.push({model: model, picture: pic, price: price})
    })
    return categoriesByModel
}